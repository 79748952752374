<template>
    <div>
        <div class="errorbox">
            <img src="../../assets/images/error400.jpg" alt="">
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{

        }
    }
}
</script>
<style scoped>
.errorbox{ text-align: center; padding-top: 60px;}
.errorbox img{ width: 50%; }
</style>